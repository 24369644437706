import React from 'react'
import tw, { styled } from 'twin.macro'
import MarkerSvg from '@elements/MarkerSvg'
import { motion, useViewportScroll } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { notSafari } from './LandingHeader'

const Section = styled.div`
  ${tw`relative lg:mx-8`};
`

const Wrap = tw.div`max-w-master mx-auto px-5 xl:px-0 grid md:grid-cols-12 relative gap-2 lg:gap-5`
const BeerLogo = tw.div`w-full flex items-start md:items-center justify-start xs:justify-center lg:px-0 lg:col-span-4 col-span-8 order-1 lg:order-2 z-10`

const BeerImage = styled.div`
  ${tw`relative z-10 order-2 col-span-4 lg:col-span-2 lg:order-1 `}
`
const Hop = tw.div`absolute pr-[36px] md:pr-0 bottom-[-40px] md:top-[42px] right-[54px] md:right-[80px] z-0 translate-x-20 -translate-y-10 md:block`

const TextBarWrap = tw.div`font-lobster text-white sm:py-1 md:py-4 lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl mb-0 mt-[91px] lg:mt-24 mx-[19px] sm:mx-7 lg:mx-auto xs:px-5 text-center sm:text-15px md:text-2xl border-t border-b border-white z-10 relative`
const TextBar = tw.div`max-w-screen-sm mx-auto `
const Badge = tw.img`absolute translate-x-7 -translate-y-1/2 right-[-10px] w-[110px] md:top-[130px] xs:right-[-50px] top-6 md:w-[150px] lg:w-[191px] md:right-8 z-[-1]`

const Content = styled.div`
  ${tw`relative max-w-screen-lg mx-auto md:px-5`}
`
const Heading = tw.h3`font-DIN text-heading md:max-w-screen-xs mx-auto text-center pt-11 pb-6 md:pb-11 md:text-[32px] sm:leading-[38px] text-[22px] leading-[26px] sm:text-2xl max-w-[300px] lg:p-0`
const Grid = styled.div`
  ${tw`grid items-center grid-cols-2 mt-10 mb-8 md:mb-5`}
`
const ImageWrap = styled.div`
  ${tw`relative col-span-2 md:col-span-1 xxs:max-w-[85%] max-w-[75%] md:max-w-max`}
`
const ListWrap = styled.div`
  ${tw`flex flex-col items-center col-span-2 mt-6 md:col-span-1 md:mt-0`}
`
const List = tw.ul`list-disc ml-10 mr-4 xxs:mx-10 lg:mx-0`
const ListItem = tw.li`font-lobster pb-2 xs:pb-4 text-18px xs:text-2xl md:text-3xl md:leading-[2.75rem]`

const Table = styled.div`
  ${tw`mt-8 md:mt-14 col-span-12 h-[375px] w-[95%] max-w-[270px] md:w-11/12 mx-auto md:max-w-[480px] md:h-[232px] md:m-auto lg:col-span-6 bg-transparent relative rounded z-10 order-3`}

  img {
    ${tw`max-h-full md:max-h-['auto']`}
  }
`
const TableGrid = styled.div`
  ${tw`grid w-full h-full grid-cols-12 grid-rows-3 md:grid-rows-2`}
  @media (max-width: 768px) {
    &::after {
      ${tw`absolute top-0 left-0 right-0 h-1 bg-bottom bg-repeat-x md:top-auto md:bottom-0 bg-gradient-to-r`}
      content: '';
      background-image: linear-gradient(
        to right,
        #ffffff 33%,
        rgba(255, 255, 255, 0) 0%
      );
      background-size: 8px 2px;
    }
  }
`
const TableRow = styled.div`
  ${tw`relative grid items-center justify-center order-2 w-full col-span-5 row-span-2 md:grid-cols-2 md:row-span-1 md:col-span-7 md:order-3`}

  &::after {
    ${tw`absolute h-1 bg-bottom bg-repeat-x top-1/2 left-2 right-2 bg-gradient-to-r md:h-auto md:absolute md:left-0 md:right-0 md:w-1 md:mx-auto md:bg-right md:bg-repeat-y md:top-4 md:bottom-4`}
    content: '';
    background-image: linear-gradient(
      to right,
      #ffffff 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 8px 2px;
  }
  @media (min-width: 768px) {
    &::after {
      content: '';
      background-image: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0) 0%);
      background-size: 2px 8px;
    }
  }
`
const Table01 = styled.div`
  ${tw`relative flex items-center justify-center order-1 col-span-7 row-span-2 p-0 md:p-0 md:col-span-5`}

  &::after {
    ${tw`absolute right-0 w-1 mx-auto bg-right bg-repeat-y top-4 bottom-3`}
    content: '';
    background-image: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0) 0%);
    background-size: 2px 8px;
  }
  @media (min-width: 768px) {
    &::before {
      ${tw`absolute left-0 w-1 mx-auto bg-right bg-repeat-y top-4 bottom-4`}
      content: '';
      background-image: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0) 0%);
      background-size: 2px 8px;
    }
  }
`
const Table02 = styled.div`
  ${tw`relative flex items-center justify-center order-5 col-span-12 row-start-3 pb-5 md:px-5 md:order-2 md:row-span-1 md:col-span-7 md:p-0`}

  &::after {
    ${tw`absolute top-0 h-1 bg-bottom bg-repeat-x right-2 left-2 md:top-auto md:bottom-0 bg-gradient-to-r`}
    content: '';
    background-image: linear-gradient(
      to right,
      #ffffff 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 8px 2px;
  }
`
const Table03 = styled.div`
  ${tw`relative flex items-center justify-center order-2 p-5 md:p-0`}
`
const Table04 = styled.div`
  ${tw`flex items-center justify-center order-1 p-5 md:order-3 md:p-0`}
`

const MarkerWrap = styled(motion.div)`
  ${tw`relative p-5 mt-[27px] md:mt-0`}
`
const StyledMarker = styled(MarkerSvg)`
  ${tw`absolute left-0 lg:top-2`}
`

const Top = styled.div`
  background-color: ${({ color }) => color};
  ${tw`relative pb-16 pt-14 md:pt-40 md:pb-36`}
`
const BoxSection = styled.div`
  ${tw`flex flex-col items-center pt-12 pb-14 sm:py-8 sm:pb-6 max-w-screen-2xl mx-7 lg:mx-auto`}
  h2 {
    ${tw`font-lobster pb-4 text-18px xs:text-2xl md:text-[26px] min-h-[60px] sm:min-h-[92px]`}
    color: ${({ color }) => color};
  }
`
const Separator = styled.div`
  ${tw`h-px mx-5 lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl lg:mx-auto`}
  background-color: ${({ color }) => color};
`

const Glow = styled(motion.div)`
  ${tw`absolute top-0 left-0 h-full bg-white w-[200%] blur-[214px] opacity-[0.35] z-[-1] hidden lg:block`}
  transform: translateZ(0);
`

const Bottom = styled.div`
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 7.81%,
    rgba(196, 196, 196, 0.541511) 100%
  );
`

const BoxSectionImageWrap = styled.div`
  ${tw`grid max-w-3xl gap-5 mx-auto lg:px-5 lg:gap-0`}
`

const BoxSectionImageWrapEn = styled.div`
  ${tw`grid max-w-2xl gap-5 mx-auto lg:px-5 lg:gap-0`}
`

const Lemon = styled(motion.img)`
  ${tw`object-contain absolute h-auto w-auto bottom-[-20px] right-[-40%] max-w-[120px] lg:block hidden`}
`

const ContentImage = styled.img`
  ${tw`min-h-[280px] xs:min-h-[318px] sm:min-h-[400px] md:max-h-full`}
  @media (max-width: 480px) {
    transform: ${({ isRadler }) =>
      isRadler ? 'translateX(-42px)' : 'translateX(10px)'};
  }
`

const MobileHop = styled.img`
  ${tw`w-[200px] xxs:w-80 md:hidden`}
  transform: rotateX(180deg);
`

const BeerSection = ({ content, id, language }) => {
  const [beerRef, beerIsVisible] = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const [yPosition, setYPosition] = React.useState(0)
  const { scrollYProgress } = useViewportScroll()
  const { isMobile, isTablet, isMaster } = useBreakpoint()
  const heavyAnimations = notSafari || isMaster

  const glowVariants = {
    init: {
      opacity: 0,
    },
    animate: {
      opacity: 0.35,
      transition: {
        delay: 0.2,
        duration: 1,
        type: 'spring',
        bounce: 0.7,
      },
    },
  }

  const beerVariants = {
    init: {
      y: '-20%',
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 1,
        type: 'spring',
        bounce: 0.6,
      },
    },
  }

  const lemonVariants = {
    init: {
      y: '-200%',
      x: -1,
      opacity: 0,
    },
    animate: {
      y: 0,
      x: -1,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 1,
        type: 'spring',
        bounce: 0.7,
      },
    },
  }

  const markerVariants = {
    init: {
      x: 0,
    },
    animate: {
      x: isMobile ? content.markerMobile : content.markerDefault,
      transition: {
        delay: 0.2,
        duration: 1,
      },
    },
  }

  React.useEffect(() => {
    scrollYProgress.onChange((val) => setYPosition(val))
  }, [scrollYProgress, yPosition])

  const list = isMobile ? content.listMobile : content.list

  return (
    <>
      <Section
        id={id}
        className={`${id === 'helles' && 'mb-0 mt-5 lg:mt-14 lg:mb-8'}`}
      >
        <Top color={content.color}>
          <Wrap>
            <BeerImage ref={beerRef}>
              {notSafari && (
                <Glow
                  animate={beerIsVisible ? 'animate' : 'init'}
                  variants={glowVariants}
                />
              )}
              <motion.img
                src={content.beer.default}
                alt={content.beerText}
                animate={beerIsVisible ? 'animate' : 'init'}
                variants={!isTablet ? beerVariants : undefined}
                tw="xs:height[450px] object-contain w-auto lg:block hidden"
              />
              <motion.img
                src={content.beerMobile.default}
                alt={content.beerText}
                animate={beerIsVisible ? 'animate' : 'init'}
                variants={!isTablet ? beerVariants : undefined}
                tw="xs:height[450px] object-contain w-auto lg:hidden block"
              />
              {id === 'radler' && (
                <Lemon
                  src={content.lemon.default}
                  alt={content.lemonText}
                  animate={beerIsVisible ? 'animate' : 'init'}
                  variants={!isTablet ? lemonVariants : undefined}
                />
              )}
            </BeerImage>
            <BeerLogo>
              <img src={content.logo.default} alt={content.logoText} tw="sm:max-h-full" />
            </BeerLogo>
            <Table ref={beerRef}>
              <TableGrid>
                <Table01>
                  <img src={content.tb01.default} alt={content.tb01Text} tw="p-3 " />
                </Table01>
                <Table02>
                  <MarkerWrap>
                    <StyledMarker
                      animate={beerIsVisible ? 'animate' : 'init'}
                      variants={markerVariants}
                      stroke={content.color}
                    />
                    <img
                      src={content.tb02.default}
                      alt={content.tb02Text}
                      tw="min-h-[94px] min-w-[236px] md:min-h-0"
                    />
                  </MarkerWrap>
                </Table02>
                <TableRow>
                  <Table03>
                    <img src={content.tb03.default} alt={content.tb03Text} />
                  </Table03>
                  <Table04>
                    <img src={content.tb04.default} alt={content.tb04Text} />
                  </Table04>
                </TableRow>
              </TableGrid>
            </Table>
          </Wrap>
          <Hop>
            <img tw="w-full hidden md:block" src={content.hop.default} alt={content.hopText} />
            <MobileHop src={content.hop.default} alt={content.hopText} />
          </Hop>
          <TextBarWrap
            className={`${
              content.type === 'radler'
                ? 'text-15px sm:text-15px md:text-2xl'
                : 'text-[11px] indent-[20px] xs:text-15px md:text-2xl'
            }`}
          >
            <TextBar
              dangerouslySetInnerHTML={{
                __html: isMobile ? content.textBarMobile : content.textBar,
              }}
            />
          </TextBarWrap>
        </Top>
        <Bottom>
          <Content className="pt-1.5 sm:pt-10 lg:pt-20">
            <Badge
              src={content.badge.default}
              alt={content.badgeText}
              className={'md:block hidden'}
            />
            <Heading dangerouslySetInnerHTML={{ __html: content.heading }} />
            <Grid
              className={`${content.type === 'radler' && 'xl:translate-x-12'}`}
            >
              <ImageWrap
                className={`${
                  content.type === 'radler' ? 'md:order-0' : 'md:order-2'
                }`}
              >
                <Badge
                  src={content.badge.default}
                  alt={content.badgeText}
                  className={`${
                    content.type === 'radler'
                      ? 'md:hidden block !right-[-10px] xxs:!right-[-5px]'
                      : 'md:hidden block !right-[-38px] xxs:!right-[-20px] !top-0'
                  }`}
                />
                <ContentImage
                  src={content.contentImage.default}
                  alt={content.contentImageText}
                  tw=""
                  isRadler={content.type === 'radler'}
                />
              </ImageWrap>
              <ListWrap>
                <List
                  className={`${
                    content.type === 'helles'
                      ? 'md:pl-10'
                      : 'lg:min-w-[480px] xl:min-w-[606px] lg:!ml-16 xl:!ml-20'
                  }`}
                >
                  {list.map((item, index) => {
                    return (
                      <ListItem
                        dangerouslySetInnerHTML={{ __html: item }}
                        key={`${content.type}-list-${index}`}
                        className={`${
                          content.type === 'radler'
                            ? 'text-radler'
                            : 'text-helles'
                        }`}
                      ></ListItem>
                    )
                  })}
                </List>
              </ListWrap>
            </Grid>
          </Content>
          <Separator color={content.color} />
          <Content className="pt-0 sm:pt-0 lg:pt-0">
            <BoxSection color={content.color}>
              <h2>{content.bottomTitle}</h2>
              {language === 'de' &&
                <BoxSectionImageWrap className={"grid-cols-11 items-center"}>
                  {content.b01 &&
                    <div tw="flex justify-center items-center col-span-4 md:col-span-3 h-[66px] xs:h-[90px] lg:h-[166px]">
                      <a href="https://www.erdinger-brauhaus.de/handelspartner/" tw="h-full">
                        <img
                          src={content.b01.default}
                          alt={content.b01Text}
                          tw="h-full object-contain"
                        />
                      </a>
                    </div>
                  }
                  {content.b02 &&
                    <div tw="flex justify-center items-center col-span-2 md:col-span-3 h-[59px] xs:h-[80px] lg:h-[147px]">
                      <a href="https://www.erdinger-brauhaus.de/handelspartner/" tw="h-full">
                        <img
                          src={content.b02.default}
                          alt={content.b02Text}
                          tw="h-full object-contain"
                        />
                      </a>
                    </div>
                  }
                  {content.b03 &&
                    <div tw="flex justify-center items-center col-span-3 md:col-span-3 h-[72px] xs:h-[95px] lg:h-[150px]">
                      <a href="https://www.erdinger-brauhaus.de/handelspartner/" tw="h-full">
                        <img
                          src={content.b03.default}
                          alt={content.b03Text}
                          tw="h-full object-contain"
                        />
                      </a>
                    </div>
                  }
                  {content.b05 &&
                    <div tw="flex justify-center items-center col-span-2 md:col-span-2 h-[59px] xs:h-[80px] lg:h-[147px]">
                      <a href="https://www.erdinger-brauhaus.de/handelspartner/" tw="h-full">
                        <img
                          src={content.b05.default}
                          alt={content.b05Text}
                          tw="h-full object-contain"
                        />
                      </a>
                    </div>
                  }
                </BoxSectionImageWrap>
              }
              {language === 'en' &&
                <BoxSectionImageWrapEn className={"grid-cols-10 items-center"}>
                  {content.b01 &&
                    <div tw="flex justify-center items-center col-span-5 md:col-span-4 h-[66px] xs:h-[90px] lg:h-[166px]">
                      <img
                        src={content.b01.default}
                        alt={content.b01Text}
                        tw="h-full object-contain"
                      />
                    </div>
                  }
                  {content.b04 &&
                    <div tw="flex justify-center items-center col-span-3 md:col-span-4 h-[59px] xs:h-[80px] lg:h-[147px]">
                      <img
                        src={content.b04.default}
                        alt={content.b04Text}
                        tw="h-full object-contain"
                      />
                    </div>
                  }
                  {content.b05 &&
                    <div tw="flex justify-center items-center col-span-2 md:col-span-2 h-[59px] xs:h-[80px] lg:h-[147px]">
                      <img
                        src={content.b05.default}
                        alt={content.b05Text}
                        tw="h-full object-contain"
                      />
                    </div>
                  }
                </BoxSectionImageWrapEn>
              }
            </BoxSection>
          </Content>
          <p tw="max-w-screen-2xl mx-auto text-11px lg:text-15px font-roboto text-gray/20 text-center pb-2">
            {content.calories}
          </p>
        </Bottom>
      </Section>
    </>
  )
}

export default BeerSection
